import React from 'react';
import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Tabla from '../Tabla';
import MatchList from '../MatchList';
import MatchCardGroup from '../MatchCardGroup';
import Players from '../Players';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
class Campeonato2025 extends React.Component {

  constructor(props){
    super(props);
        this.comeBack = this.comeBack.bind(this);
	this.handleChange = this.handleChange.bind(this);
	var id_list = [];
	for (var i = 182; i <= 194; i++) {
        	id_list.push(i);
        }
    this.state = {
	showCards: true,
      path: this.props.path,
	kits:[],
        kit_path: '/images_fem/kits/todas/',
      ctype: this.props.ctype,
      torneo: this.props.torneo,
      id_list: id_list,

table2:[{'color':'#295e1e','shortname':'udeconce', 'rivalName':'U. de Concepción', 'pj':4, 'pg':1,'pe':1,'pp':2,'gf':3,'gc':5,'dif':-2, 'pts':4},
              {'color':'#295e1e','shortname':'iquique', 'rivalName':'Dep. Iquique', 'pj':4, 'pg':2,'pe':1,'pp':1,'gf':7,'gc':6,'dif':1, 'pts':7},
              {'color':'#295e1e','shortname':'coquimbo', 'rivalName':'Coquimbo U.', 'pj':4, 'pg':4,'pe':0,'pp':0,'gf':14,'gc':2,'dif':12, 'pts':12},
              {'color':'#E42727','shortname':'everton', 'rivalName':'Everton', 'pj':4, 'pg':0,'pe':1,'pp':3,'gf':0,'gc':13,'dif':-13, 'pts':1},
              {'color':0,'shortname':'recoleta', 'rivalName':'Dep. Recoleta', 'pj':4, 'pg':0,'pe':1,'pp':3,'gf':3,'gc':14,'dif':-11, 'pts':1},
              {'color':'#E42727','shortname':'wanderers', 'rivalName':'S. Wanderers', 'pj':4, 'pg':0,'pe':1,'pp':3,'gf':3,'gc':8,'dif':-5, 'pts':-5},
              {'color':'#295e1e','shortname':'colocolo', 'rivalName':'Colo Colo', 'pj':4, 'pg':4,'pe':0,'pp':0,'gf':23,'gc':0,'dif':23, 'pts':12},
              {'color':'#295e1e','shortname':'udechile', 'rivalName':'U. de Chile', 'pj':4, 'pg':3,'pe':1,'pp':0,'gf':15,'gc':3,'dif':12, 'pts':10},
                {'color':0,'shortname':'palestino', 'rivalName':'Palestino', 'pj':4, 'pg':1,'pe':0,'pp':3,'gf':7,'gc':10,'dif':-3, 'pts':3},
                {'color':0,'shortname':'audax', 'rivalName':'A. Italiano', 'pj':4, 'pg':0,'pe':1,'pp':3,'gf':4,'gc':16,'dif':-12, 'pts':1},
                {'color':'#295e1e','shortname':'catolica', 'rivalName':'U. Católica', 'pj':4, 'pg':2,'pe':0,'pp':2,'gf':9,'gc':8,'dif':1, 'pts':6},
                {'color':'#295e1e','shortname':'huachipato', 'rivalName':'Huachipato', 'pj':4, 'pg':2,'pe':0,'pp':2,'gf':4,'gc':7,'dif':-3, 'pts':6},
                {'color':0,'shortname':'morning', 'rivalName':'S. Morning', 'pj':4, 'pg':2,'pe':0,'pp':2,'gf':5,'gc':13,'dif':-8, 'pts':3},
              {'color':'#295e1e','shortname':'union', 'rivalName':'U. Española', 'pj':4, 'pg':3,'pe':1,'pp':0,'gf':11,'gc':3,'dif':8, 'pts':10}]





    };
  }

  componentDidMount() {
		this.getKits();
		let newtable = this.sortTable(this.state.table2);
  		this.setState({table2:newtable});
	}
 getKits(){

        fetch("https://www.registrosudec.com:5000/kits_femenino", {

    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      id_list: this.state.id_list
    })
    }).then(
                res => res.json()
                )
  .then(
    (result) => {
        console.log(result)
        this.setState({ 
        kits: result['kits']}) //[{path:"45.png", kit:yellow, short:"blue", socks:"blue", count:15, 
    },
    // Nota: es importante manejar errores aqu   y no en
    // un bloque catch() para que no interceptemos errores
    // de errores reales en los componentes.
    (error) => {
      console.log(error);
      this.setState({fecha: []})
    }
  );
}




  handleChange(val){
    console.log(val);
    if(val===1){
      this.setState({showCards:true});
    }
    else{
      this.setState({showCards:false});
    }
  }

comeBack(){
this.setState({path:this.state.path});
}

subText(color, text){

let comp = [];
comp.push(

	<Row className="justify-content-md-center">
            <Col lg={2} xl={2} >

        <Table bordered={ false } size="sm">
        <tr style={{border: 'none', textAlign: 'rigth' }}>
        <td bgcolor={color}>
        </td>
        <td style={{border: 'none', textAlign: 'center'}}> {text} </td>
        </tr>
        </Table>
        </Col>
        </Row>



)
return comp;

}


sortTable(table){
       let aux;
      for(let i=table.length-1;i>0;i--){
             for(let j =0; j<i; j++){
                 if( (table[j].pts < table[j+1].pts) || 
                     ((table[j].pts === table[j+1].pts) && (table[j].dif < table[j+1].dif)) ||
                     ((table[j].pts === table[j+1].pts) && (table[j].dif === table[j+1].dif) && (table[j].gf < table[j+1].gf))){
			aux = table[j+1];
			table[j+1] = table[j];
                        table[j] = aux;
		} 
	     }		
	}
return table;

}


render(){


  let subT = this.subText('#295e1e', 'Clasificado a Play-Off');
  let subC = this.subText('#E42727', 'Desciende a Primera B');
  return(

	<div style={{textAlign: 'center' }}>
	      <div style={{ height: '50px' }}></div>
        <div className="mytitlepartidos">  Indumentaria UDEC Campeonato 2025</div>
        <div style={{ height: '50px' }}></div> 

        <div style={{display: 'inline-block' }}>
        <table style={{textAlign: 'center' }}>
        <tr>
           {this.state.kits.map((i) => {
                   return ( <td> <Image  className="escudo-home" src={this.state.kit_path+i['path']+".png"}/> </td>)
            })}
        </tr>
        <tr>
        {this.state.kits.map((i) => {
                   return ( <td> <Image  className="myshort" src={this.state.kit_path+i['short']+".png"}/> </td>)
            })}
        </tr>
        <tr>
        {this.state.kits.map((i) => {
                   return ( <td> <Image  className="myshort" src={this.state.kit_path+'socks-'+i['socks']+".png"}/> </td>)
            })}
        </tr>

        <tr>
                {this.state.kits.map((i) => {
                   return ( <td class="circle"> {i['count']} </td>)
            })}
        </tr>
        </table>
        </div>

      <div style={{ height: '50px' }}></div>
	<div className="mytitlepartidos"> Tabla General </div>
	<div style={{ height: '10px' }}></div>
	{<Tabla teams={this.state.table2} />}
	<div style={{ height: '10px' }}></div>
        <div> * S. Morning tiene 3 puntos menos por sanción en torneo pasado</div>
	<div> * S. Wanderers tiene 6 puntos menos por sanción en torneo pasado</div>
        <div style={{ height: '10px' }}></div>
         {subT}
	{subC}


	
	<div style={{ height: '100px' }}></div>
	<div className="mytitlepartidos">  Partidos UDEC Campeonato 2025</div>
	<div style={{ height: '10px' }}></div>

	<ToggleButtonGroup type="radio" name="options" defaultValue={1} onChange={this.handleChange}>
            <ToggleButton variant="secondary" value={1}>Tarjetas</ToggleButton>
            <ToggleButton variant="secondary" value={2}>Listado</ToggleButton>
          </ToggleButtonGroup>
	 <div style={{ height: '10px' }}></div>
	<Container>
	<div className={this.state.showCards ? '': 'hiddenCard'}>
           <MatchCardGroup id_list={this.state.id_list}></MatchCardGroup>
         </div>
         <div className={this.state.showCards ? 'hiddenCard': ''}>

           <MatchList id_list={this.state.id_list} showName={true}></MatchList>

         </div>
	</Container>

	<div style={{ height: '100px' }}></div>
        <div className="mytitlepartidos">  Jugadores UDEC Campeonato 2025</div>
        <div style={{ height: '10px' }}></div>

	<Players torneo="Campeonato 2025" />
     </div>


  )
}

}
export default Campeonato2025;
