import React from 'react';
import Button from 'react-bootstrap/Button';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';




class SummaryTournament extends React.Component {

  constructor(props){
    super(props);
	this.handleShow = this.handleShow.bind(this);
        this.comeBack = this.comeBack.bind(this);
    this.state = {
      rivalName: '',
      path: this.props.path,
      all: [{'name':'Primera División', 'pj': 185, 'pg':74, 'pe': 27, 'pp': 84, 'gf': 338, 'gc':406, 'pts':249, 'link': 'primera'}],
	      detail: [{'name':'Apertura 2016', 'pj':9, 'pg':5, 'pe': 0, 'pp': 4, 'gf': 18, 'gc':17, 'pts':15, 'link': 'a2016', 'path':'primera', 'pos':10},
                {'name':'Clausura 2016', 'pj':18, 'pg':6, 'pe': 3, 'pp': 9, 'gf': 40, 'gc':55, 'pts':21, 'link': 'c2016', 'path':'primera', 'pos':13},
                {'name':'Apertura 2017', 'pj':10, 'pg':5, 'pe': 2, 'pp': 3, 'gf': 31, 'gc':27, 'pts':17, 'link': 'a2017', 'path':'primera', 'pos':6},
                {'name':'Clausura 2017', 'pj':18, 'pg':8, 'pe': 3, 'pp': 7, 'gf': 39, 'gc':34, 'pts':27, 'link': 'c2017', 'path':'primera', 'pos':9},
                {'name':'Campeonato 2018', 'pj':17, 'pg':10, 'pe': 3, 'pp': 4, 'gf': 35, 'gc':20, 'pts':33, 'link': '2018', 'path':'primera', 'pos':7},
                {'name':'Campeonato 2019', 'pj':21, 'pg':6, 'pe': 2, 'pp': 13, 'gf': 36, 'gc':72, 'pts':20, 'link': '2019', 'path':'primera', 'pos':12},
                {'name':'Campeonato 2020', 'pj':1, 'pg':1, 'pe': 0, 'pp': 0, 'gf': 5, 'gc':3, 'pts':3, 'link': '2020', 'path':'primera', 'pos':6},
                {'name':'Transición 2020', 'pj':7, 'pg':4, 'pe': 1, 'pp': 2, 'gf': 23, 'gc':18, 'pts':13, 'link': 't2020', 'path':'primera', 'pos':5},
                {'name':'Campeonato 2021', 'pj':16, 'pg':6, 'pe': 3, 'pp': 7, 'gf': 20, 'gc':32, 'pts':21, 'link': '2021', 'path':'primera', 'pos':7},
                {'name':'Campeonato 2022', 'pj':21, 'pg':7, 'pe': 4, 'pp': 10, 'gf': 26, 'gc':35, 'pts':25, 'link': '2022', 'path':'primera', 'pos':6},
                {'name':'Campeonato 2023', 'pj':19, 'pg':8, 'pe': 3, 'pp': 8, 'gf': 37, 'gc':39, 'pts':27, 'link': '2023', 'path':'primera', 'pos':9},
		{'name':'Campeonato 2024', 'pj':24, 'pg':7, 'pe': 2, 'pp': 15, 'gf': 24, 'gc':49, 'pts':23, 'link': '2024', 'path':'primera', 'pos':10},
		{'name':'Campeonato 2025', 'pj':4, 'pg':1, 'pe': 1, 'pp': 2, 'gf': 3, 'gc':5, 'pts':4, 'link': '2025', 'path':'primera', 'pos':7}
                ]



    };
  }

  componentDidMount() {
	}



assignData(){

   var total = {'name':'Total', 'pj': 0, 'pg':0, 'pe': 0, 'pp': 0, 'gf': 0, 'gc':0, 'pts':0};

   if(this.state.path === 'todos'){
	for(let j = 0; j < this.state.all.length; j++){

		total['pj'] = total['pj'] + this.state.all[j]['pj'];
		total['pg'] = total['pg'] + this.state.all[j]['pg'];
		total['pe'] = total['pe'] + this.state.all[j]['pe'];
		total['pp'] = total['pp'] + this.state.all[j]['pp'];
		total['gf'] = total['gf'] + this.state.all[j]['gf'];
		total['gc'] = total['gc'] + this.state.all[j]['gc'];
		total['pts'] = total['pts'] + this.state.all[j]['pts']
	}

	return (
		
		    <tbody>{ this.state.all.map((_type, i) => {
                   return ( _type.pj !== 0 &&  <tr style={{textAlign: 'center' }}>
                               <td ><button className="mynewbutton" onClick={() => this.handleShow(_type.link)}>{_type.name}</button></td>
                               <td >{_type.pj}</td>
                               <td >{_type.pg}</td>
                               <td >{_type.pe}</td>
                               <td >{_type.pp}</td>
                               <td >{_type.gf}</td>
                               <td >{_type.gc}</td>
                               <td >{_type.gf - _type.gc}</td>
                               <td >{_type.pts}</td>
                                 </tr>

                     )
            })
		}

	<td> {total.name}</td><td >{total.pj}</td>
                      <td>{total.pg}</td><td >{total.pe}</td>
                      <td >{total.pp}</td><td >{total.gf}</td><td >{total.gc}</td>
                               <td >{total.gf - total.gc}</td>
                               <td >{total.pts}</td>

	</tbody>

	)

   }
	else{

	total = {'name':'Total', 'pj': 0, 'pg':0, 'pe': 0, 'pp': 0, 'gf': 0, 'gc':0, 'pts':0};

        for(let j = 0; j < this.state.detail.length; j++){
		if(this.state.detail[j]['path'] === this.state.path){

                total['pj'] = total['pj'] + this.state.detail[j]['pj'];
                total['pg'] = total['pg'] + this.state.detail[j]['pg'];
                total['pe'] = total['pe'] + this.state.detail[j]['pe'];
                total['pp'] = total['pp'] + this.state.detail[j]['pp'];
                total['gf'] = total['gf'] + this.state.detail[j]['gf'];
                total['gc'] = total['gc'] + this.state.detail[j]['gc'];
                total['pts'] = total['pts'] + this.state.detail[j]['pts'];
	       }
        }

           return(<tbody>
	
	    { this.state.detail.map((_type, i) => {
                   return ( _type.path === this.state.path &&  
                               <tr style={{textAlign: 'center' }}>
			       <td >{_type.pos}</td>
				<td><Link className="link-name" to={"/campeonatos/" + _type.link}> {_type.name}</Link></td>
                               <td >{_type.pj}</td>
                               <td >{_type.pg}</td>
                               <td >{_type.pe}</td>
                               <td >{_type.pp}</td>
                               <td >{_type.gf}</td>
                               <td >{_type.gc}</td>
                               <td >{_type.gf - _type.gc}</td>
                               <td >{_type.pts}</td>
                                 </tr>

                     )
            })
	}
		<td> </td>
	        <td> {total.name}</td><td >{total.pj}</td>
                      <td>{total.pg}</td><td >{total.pe}</td>
                      <td >{total.pp}</td><td >{total.gf}</td><td >{total.gc}</td>
                               <td >{total.gf - total.gc}</td>
                               <td >{total.pts}</td>
	</tbody>
	)

	}

}


handleShow(p){

this.setState({path:p})
console.log(this.state);
}

comeBack(){
this.setState({path:'todos'});
console.log(this.state);
}

setTable(){

var componente = [];

      componente.push(
	<Row className="justify-content-md-center">
    <Col lg={6} xl={6}  >
    <Table variant="dark" striped bordered hover size="sm">
  <thead>
    <tr style={{textAlign: 'center' }}>
	{this.state.path !== 'todos' && <th> Pos </th>}
    <th>Torneo</th>
      <th>PJ</th>
      <th>PG</th>
      <th>PE</th>
      <th>PP</th>
      <th>GF</th>
      <th>GC</th>
      <th>DIF</th>
      <th>PTS</th>
    </tr>
  </thead>
	{ this.assignData() }
  </Table>
  </Col>






  </Row>	)

return componente;

}



render(){

   let componente = this.setTable();
  return(

<div>
{this.state.path !== 'todos' && <div className='back-button'><Button variant="dark" onClick={() => this.comeBack()}> Volver </Button></div>}
{this.state.path !== 'todos' && <div style={{ height: '20px'}}></div>}

	{componente}
</div>
  )
}

}
export default SummaryTournament;
