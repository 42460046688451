import React from 'react';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Table from 'react-bootstrap/Table';
import AllMatch from './AllMatch';
import Button from 'react-bootstrap/Button';

class Partidos extends React.Component {

  constructor(props){
    super(props);
   this.indexList = ['2016', '2017', '2018', '2019', '2020', '2021', '2022', '2023', '2024', '2025']
   this.yearMap = {'2016':[1,27], '2017':[28,55], '2018':[56, 72], '2019':[73, 93], '2020':[94,101], '2021': [102, 117], '2022': [118, 138], '2023': [139, 157], '2024': [158,181], '2025': [182,194]}
    this.state = {
      showCards: false,
      matches: [],
      index: 9,
      start: 182,
      end: 194,
      total: 194
    };
  }

nextGroup(init, fin){
	if(this.state.index + 1 < this.indexList.length){
	let s = this.yearMap[this.indexList[this.state.index + 1]][0]
	let f = this.yearMap[this.indexList[this.state.index + 1]][1]
	this.setState({index: this.state.index + 1, start: s, end: f} , () => {
		this.getPartidos();
	});
}
}

previousGroup(init, fin){
	if(this.state.index - 1 >= 0){
        let s = this.yearMap[this.indexList[this.state.index - 1]][0]
        let f = this.yearMap[this.indexList[this.state.index - 1]][1]
        this.setState({index: this.state.index - 1, start: s, end: f} , () => {
                this.getPartidos();
        });
	}
}


  componentDidMount(){

     this.getPartidos();

  }


  getPartidos(){
    fetch("https://www.registrosudec.com:5000/lista_allpartidos_femenino", {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      start: this.state.start, end: this.state.end
    })
    }).then(res => res.json())
  .then(
    (result) => {
     
     this.setState({matches: result['ids']})
    },
    (error) => {
      console.log(error);
      this.setState({matches: []})
    }
  )
  }


  render(){

	console.log(this.state.matches);


    return(

	<Container>
      <div style={{textAlign: 'center' }}>
      <div style={{ height: '20px' }}></div>
    <span class="mytitlepartidos"> Lista de Partidos </span>
 <div style={{ height: '10px' }}></div>
      <div style={{textAlign: 'center' }}>
      <Image  className="myescudo-away-main" data-tip data-for={'udeconce-escudo'} src={'/images_fem/escudos/udeconce.png'}/>
      </div>

        <div style={{ height: '70px' }}></div>
<Button onClick={() => this.previousGroup(this.state.start, this.state.end)} variant="dark">{'<'}</Button>
{"  "+this.indexList[this.state.index]+" "}
<Button onClick={() => this.nextGroup(this.state.start, this.state.end)} variant="dark">{'>'}</Button>

<div style={{ height: '10px' }}></div>
	<Table responsive variant="dark"  striped bordered hover size="sm">
     <thead>
     <tr>
       <th>Fecha</th>
       <th>Local</th>
       <th>vs.</th>
       <th>Visita</th>
       <th>Estadio</th>
       <th>TV</th>
	<th>Video</th>
     </tr>
   </thead>

     <tbody>

    {this.state.matches.map((i) => {
                   return ( <AllMatch shortname={i['shortname']} fixture={i['fixture']}
                                state={i['state']} rivalName={i['rivalName']}
	    		        goal_home={i['goal_home']} id={i['id']}
				goal_away={i['goal_away']} stadium={i['stadium']}
			        tv={i['tv']} video={i['video']}team_image={"/images_fem/escudos/"+i['shortname']+".png"} ></AllMatch>   )
            })}
            </tbody>


         </Table>
<Button onClick={() => this.previousGroup(this.state.start, this.state.end)} variant="dark">{'<'}</Button>
{"  "+this.indexList[this.state.index]+" "}
<Button onClick={() => this.nextGroup(this.state.start, this.state.end)} variant="dark">{'>'}</Button>
<div style={{ height: '70px' }}></div>
	</div>


</Container>


    )

}
}
export default Partidos;
